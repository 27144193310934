/* Strain.js */

@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@400;500;600;700;800;900&display=swap');

.toggle {
  display: none;
}

.App {
  font-family: 'Kanit', sans-serif;
  font-weight: 400;
  background-color: #192831;
  padding-bottom: 5rem;
}
